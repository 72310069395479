"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const helpers_1 = require("@freelancerepublik/helpers");
exports.default = (0, vue_1.defineComponent)({
    __name: 'UserNotifications',
    props: {
        notifications: null
    },
    setup(__props) {
        const $store = (0, vue_2.getCurrentInstance)()?.proxy?.$store;
        function hideNotification(id) {
            $store.dispatch('messages/popFlashMessage', id);
        }
        return { __sfc: true, $store, hideNotification, capitalizeFirstLetter: helpers_1.capitalizeFirstLetter };
    }
});
