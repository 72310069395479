"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const rudderanalytics = __importStar(require("rudder-sdk-js"));
// ! we create a dummy object to avoid errors when rudderstack is not initialized
// we use a proxy to log all calls to rudderstack
const dummyRudderstack = new Proxy({}, {
    get: (target, propKey) => (...args) => {
        console.log(`rudderstack.${String(propKey)}`, ...args);
    },
});
function trackUserSignup(flow) {
    rudderanalytics.track('User signed up', {
        flow,
    });
}
function trackUserCreated(user) {
    rudderanalytics.track('User Created', {
        user,
    });
}
function trackUserUpdated({ firstUse, user, }) {
    rudderanalytics.track('User Updated', {
        first_use: firstUse,
        user,
    });
}
function trackMissionAssumed({ firstUse, mission, }) {
    rudderanalytics.track('Mission Assumed', {
        first_use: firstUse,
        mission,
    });
}
function trackUserLogin() {
    rudderanalytics.track('User logged in');
}
function trackFreelanceMissionInterest({ missionId, freelanceId, answer, source, }) {
    rudderanalytics.track('Freelance answers proposal', {
        missionId,
        freelanceId,
        answer,
        source,
    });
}
function trackFreelanceMarketSituationEdit(ms, uc) {
    rudderanalytics.track('Freelance updates situation', {
        marketSituation: ms,
        useCase: uc,
    });
}
function trackInsiderNotifyingFreelance({ action, shouldNotBeNotified, status, freelanceId, weightedScore, missionId, }) {
    rudderanalytics.track('Insider notifies freelance', {
        action,
        shouldNotBeNotified,
        status,
        freelanceId,
        weightedScore,
        missionId,
    });
}
function trackInsiderClickingFreelanceDetailsTab(tabLabel) {
    rudderanalytics.track('Insider clicks freelance details page', {
        'Tab clicked': tabLabel,
    });
}
function trackInsiderCancelingPreselection(data) {
    rudderanalytics.track('Insider cancels preselection basket', data);
}
function trackInsiderAddFreelanceToPreselection(selectAndNotify, data) {
    const eventName = selectAndNotify
        ? 'Insider validates and notify a preselection basket'
        : 'Insider validates a preselection basket';
    rudderanalytics.track(eventName, data);
}
function trackEditKeySkillsProposal({ missionId, freelanceId, source, }) {
    if (source === 'admin') {
        rudderanalytics.track('Insider edits freelance key skills proposal', {
            missionId,
            freelanceId,
        });
    }
    else {
        rudderanalytics.track('Freelance edits key skills proposal', {
            missionId,
            freelanceId,
        });
    }
}
function trackSelectionButtonClick(data) {
    // @ts-expect-error
    rudderanalytics.track('Insider clicks selection button', data);
}
function trackUIClicked(data) {
    rudderanalytics.track('UI Clicked', data);
}
const rudderstackFunctions = {
    trackUserLogin,
    trackUserSignup,
    trackUserCreated,
    trackUserUpdated,
    trackMissionAssumed,
    trackFreelanceMissionInterest,
    trackFreelanceMarketSituationEdit,
    trackInsiderNotifyingFreelance,
    trackInsiderClickingFreelanceDetailsTab,
    trackInsiderCancelingPreselection,
    trackInsiderAddFreelanceToPreselection,
    trackEditKeySkillsProposal,
    trackSelectionButtonClick,
    trackUIClicked,
};
exports.default = {
    install: (Vue) => {
        if (process.env.VUE_APP_RUDDERSTACK_WRITE_KEY && process.env.VUE_APP_RUDDERSTACK_DATA_PLANE) {
            rudderanalytics.load(process.env.VUE_APP_RUDDERSTACK_WRITE_KEY, process.env.VUE_APP_RUDDERSTACK_DATA_PLANE);
            Vue.prototype.$rudderanalytics = {
                ...rudderanalytics,
                ...rudderstackFunctions,
            };
        }
        else {
            Vue.prototype.$rudderanalytics = dummyRudderstack;
        }
    },
};
